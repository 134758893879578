import { FormattedMessage } from "gatsby-plugin-react-intl"
import React from "react"
import { getProgressPercentsFromCourseRegistration } from "../../../../../utils/data"
import { ResourceTile } from "../../../molecules/ResourceTile"
import { UserCourseRegistration } from "./HomePage"

import * as S from "./style"

interface HomePageContentListProps {
  registrationList: UserCourseRegistration[]
}

const HomePageContentList: React.FC<HomePageContentListProps> = ({
  registrationList,
}) => {
  return (
    <>
      {registrationList?.map(
        ({
          id: registrationId,
          course: { id, name, slug, courseorganizationSet },
          educationMaterialProgressList,
        }) => {
          const progressPercents = getProgressPercentsFromCourseRegistration(
            educationMaterialProgressList
          )

          return (
            <ResourceTile
              key={id}
              name={name}
              link={`/plan/${registrationId}`}
              organizations={courseorganizationSet?.map(
                ({ organization }) => organization
              )}
              progressPercents={progressPercents}
            />
          )
        }
      )}
    </>
  )
}

interface HomePageContentProps {
  userName: string
  userCourseRegistrationList: UserCourseRegistration[]
}

const HomePageContent: React.FC<HomePageContentProps> = ({
  userName,
  userCourseRegistrationList,
}) => {
  const inProgressRegistrationList = userCourseRegistrationList?.filter(
    ({ educationMaterialProgressList }) => {
      const progressPercents = getProgressPercentsFromCourseRegistration(
        educationMaterialProgressList
      )
      return progressPercents < 100
    }
  )
  const completedRegistrationList = userCourseRegistrationList?.filter(
    ({ educationMaterialProgressList }) => {
      const progressPercents = getProgressPercentsFromCourseRegistration(
        educationMaterialProgressList
      )
      return progressPercents >= 100
    }
  )

  return (
    <S.Main>
      <S.Wrapper>
        {userName ? (
          <S.Header>
            <FormattedMessage
              defaultMessage="Hi {userName}! Let’s continue"
              description="information"
              values={{
                userName,
              }}
            />
          </S.Header>
        ) : (
          <S.Header>
            <FormattedMessage
              defaultMessage="Start your custom learning plan"
              description="information"
            />
          </S.Header>
        )}
        {inProgressRegistrationList?.length > 0 && (
          <>
            <S.SubHeader>
              <FormattedMessage
                defaultMessage="In progress"
                description="information"
              />
            </S.SubHeader>
            <S.List>
              <HomePageContentList
                registrationList={inProgressRegistrationList}
              />
            </S.List>
          </>
        )}
        {completedRegistrationList.length > 0 && (
          <>
            <S.SubHeader>
              <FormattedMessage
                defaultMessage="Completed"
                description="information"
              />
            </S.SubHeader>
            <S.List>
              <HomePageContentList
                registrationList={completedRegistrationList}
              />
            </S.List>
          </>
        )}
      </S.Wrapper>
    </S.Main>
  )
}

export { HomePageContent }
