import { gql } from "@apollo/client"

export const userQuery = gql`
  query User {
    user {
      id
      username
      email
      firstName
      lastName
    }
  }
`
