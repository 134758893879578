import { FormattedMessage, navigate } from "gatsby-plugin-react-intl"
import React from "react"

import * as S from "./style"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

interface HomePageHelloProps {
  userName: string
}

const HomePageHello: React.FC<HomePageHelloProps> = ({ userName }) => {
  const screenshots = useStaticQuery(graphql`
    query {
      screenshot_1: file(
        relativePath: { eq: "screenshot_1_20230715231212.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      screenshot_2: file(
        relativePath: { eq: "screenshot_2_20230715231326.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      screenshot_3: file(
        relativePath: { eq: "screenshot_3_20230715231451.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // const handleBrowseClick = () => {
  //   navigate("/resources")
  // }
  // const handleAndroidClick = () => {
  //   // navigate("/resources")
  // }
  // const handleiOSClick = () => {
  //   // navigate("/resources")
  // }
  const handleCreatorsClick = () => {
    navigate("/creators")
  }
  const handleLearningSourcesClick = () => {
    navigate("/learning-sources")
  }
  const handleOrganisationsClick = () => {
    navigate("/organisations")
  }
  const handleBlogClick = () => {
    navigate("/blog")
  }
  const handleDownloadAppClick = () => {
    window.scrollTo(0, 0)
  }

  return (
    <>
      <S.Main>
        <S.Wrapper>
          {userName ? (
            <S.Header>
              <FormattedMessage
                defaultMessage="Hi {userName}! Let’s continue"
                description="information"
                values={{
                  userName,
                }}
              />
            </S.Header>
          ) : (
            <S.Header>
              <FormattedMessage
                defaultMessage="App that tracks your {internetCourses} learning progress"
                description="information description"
                values={{
                  guides: (
                    <S.Highlighted>
                      <FormattedMessage
                        defaultMessage="guides"
                        description="information description"
                      />
                    </S.Highlighted>
                  ),
                  tutorials: (
                    <S.Highlighted>
                      <FormattedMessage
                        defaultMessage="tutorials"
                        description="information description"
                      />
                    </S.Highlighted>
                  ),
                  internetCourses: (
                    <S.Highlighted>
                      <FormattedMessage
                        defaultMessage="Internet courses'"
                        description="information description"
                      />
                    </S.Highlighted>
                  ),
                }}
              />
              {/* <FormattedMessage
                defaultMessage="Your custom learning journey"
                description="information"
              /> */}
            </S.Header>
          )}
          <S.Lessonplan />
          {/* <S.MainDescription>
          </S.MainDescription> */}
          <S.DescriptionButtonContainer>
            <S.BrowseButton href="https://play.google.com/store/apps/details?id=com.learntray.app">
              <FormattedMessage
                defaultMessage="Android app"
                description="information button"
              />
            </S.BrowseButton>
            <S.BrowseButton href="https://mega.nz/file/1bJWnQxb#C9siJqlPaI2OmLogPCvhTamvgXC-H0UfqrHQwv4Xwqw">
              <FormattedMessage
                defaultMessage="Windows app (x64)"
                description="information button"
              />
            </S.BrowseButton>
            <S.BrowseButton href="https://mega.nz/file/8WJAXTpZ#6xs8JRvtkmJpZkJ7VmHKBxEYGrxsBs0u0VtKjDWcFYg">
              <FormattedMessage
                defaultMessage="Windows app (x32)"
                description="information button"
              />
            </S.BrowseButton>
            <S.BrowseButton href="https://mega.nz/file/YTRUxRiI#ecq0NfxanBZtJSev6j2KB4PB2nj8gyKGKePC7i0EEhc">
              <FormattedMessage
                defaultMessage="Linux app (snap)"
                description="information button"
              />
            </S.BrowseButton>
          </S.DescriptionButtonContainer>
        </S.Wrapper>
      </S.Main>
      <S.ResourceCatalogSection>
        <S.ResourceCatalogWrapper>
          <S.Header>
            <FormattedMessage
              defaultMessage="Catalog of courses"
              description="information"
            />
          </S.Header>
          <S.Description>
            <FormattedMessage defaultMessage="Courses you can learn from with LearnTray" />
          </S.Description>
          <S.DescriptionButtonContainer>
            <S.BrowseButton onClick={handleLearningSourcesClick}>
              <FormattedMessage
                defaultMessage="Browse"
                description="information button"
              />
            </S.BrowseButton>
          </S.DescriptionButtonContainer>
        </S.ResourceCatalogWrapper>
      </S.ResourceCatalogSection>
      <S.ResourceCreatorSection>
        <S.ResourceCreatorWrapper>
          <S.Header>
            <FormattedMessage
              defaultMessage="How to start learning a course in LearnTray?"
              description="information"
            />
          </S.Header>
          <S.ImageColumns>
            <S.Column gridArea="title1">
              <S.SubHeader>
                <FormattedMessage
                  defaultMessage="1. Click 'Add item +'"
                  description="information"
                />
              </S.SubHeader>
            </S.Column>
            <S.Column gridArea="image1">
              <S.ColumnsDescription>
                <S.ElevatedImg
                  fluid={screenshots["screenshot_1"].childImageSharp.fluid}
                  alt="LearnTray app screenshot 1"
                />
              </S.ColumnsDescription>
            </S.Column>
            <S.Column gridArea="title2">
              <S.SubHeader>
                <FormattedMessage
                  defaultMessage="2. Set 'Name', 'URL', 'Type'"
                  description="information"
                />
              </S.SubHeader>
            </S.Column>
            <S.Column gridArea="image2">
              <S.ColumnsDescription>
                <S.ElevatedImg
                  fluid={screenshots["screenshot_2"].childImageSharp.fluid}
                  alt="LearnTray app screenshot 2"
                />
              </S.ColumnsDescription>
            </S.Column>
            <S.Column gridArea="title3">
              <S.SubHeader>
                <FormattedMessage
                  defaultMessage="3. Adjust details and learn"
                  description="information"
                />
              </S.SubHeader>
            </S.Column>
            <S.Column gridArea="image3">
              <S.ColumnsDescription>
                <S.ElevatedImg
                  fluid={screenshots["screenshot_3"].childImageSharp.fluid}
                  alt="LearnTray app screenshot 3"
                />
              </S.ColumnsDescription>
            </S.Column>
          </S.ImageColumns>
        </S.ResourceCreatorWrapper>
      </S.ResourceCreatorSection>
      <S.ResourceCreatorSection>
        <S.ResourceCreatorWrapper>
          {/* <S.Header>
            <FormattedMessage defaultMessage="More" description="information" />
          </S.Header> */}
          {/* <S.SubHeader>
            <FormattedMessage
              defaultMessage="Guides, tutorials or courses creation"
              description="information"
            />
          </S.SubHeader>
          <S.DescriptionButtonContainer>
            <S.ImproveButton onClick={handleCreatorsClick}>
              <FormattedMessage
                defaultMessage="Learn more"
                description="information button"
              />
            </S.ImproveButton>
          </S.DescriptionButtonContainer>
          <S.SmallDivider /> */}
          {/* <S.SubHeader>
            <FormattedMessage
              defaultMessage="Organisations learning planning"
              description="information"
            />
          </S.SubHeader>
          <S.DescriptionButtonContainer>
            <S.ImproveButton onClick={handleOrganisationsClick}>
              <FormattedMessage
                defaultMessage="Learn more"
                description="information button"
              />
            </S.ImproveButton>
          </S.DescriptionButtonContainer>
          <S.SmallDivider /> */}
          {/* <S.SubHeader>
            <FormattedMessage defaultMessage="Blog" description="information" />
          </S.SubHeader>
          <S.DescriptionButtonContainer>
            <S.ImproveButton onClick={handleBlogClick}>
              <FormattedMessage
                defaultMessage="Check out"
                description="information button"
              />
            </S.ImproveButton>
          </S.DescriptionButtonContainer>
          <S.SmallDivider /> */}
          {/* <S.Header>
            <FormattedMessage
              defaultMessage="You have freedom"
              description="information"
            />
          </S.Header> */}
          <S.Header>
            <FormattedMessage
              defaultMessage="It's free"
              description="information"
            />
          </S.Header>
          <S.Description>
            <FormattedMessage defaultMessage="Download, test and see by yourself how much LearnTray has helped you in learning!" />
          </S.Description>
          <S.DescriptionButtonContainer>
            <S.CTAButton onClick={handleDownloadAppClick}>
              <FormattedMessage
                defaultMessage="Download the app"
                description="information button"
              />
            </S.CTAButton>
            <S.CTAButton onClick={handleLearningSourcesClick}>
              <FormattedMessage
                defaultMessage="Browse courses"
                description="information button"
              />
            </S.CTAButton>
          </S.DescriptionButtonContainer>
          <S.Divider />
          {/* <S.SubHeader>
                <FormattedMessage
                  defaultMessage="As an app user"
                  description="information"
                />
              </S.SubHeader>
              <S.ColumnsDescription>
                <FormattedMessage
                  defaultMessage="You have full freedom in deciding what, how and from what sources you learn."
                  description="description"
                />
              </S.ColumnsDescription> */}
          {/* <S.SubHeader>
                <FormattedMessage
                  defaultMessage="As a source creator"
                  description="information"
                />
              </S.SubHeader>
              <S.ColumnsDescription>
                <FormattedMessage
                  defaultMessage="You preserve full freedom in deciding what and how you teach your users."
                  description="description"
                />
              </S.ColumnsDescription> */}
          {/* <br />
          <S.Description>
            <FormattedMessage
              defaultMessage="You must be sure about it. If you doubt it, send message at: {email}."
              values={{
                email: "contact@learntray.com",
              }}
            />
          </S.Description> */}
        </S.ResourceCreatorWrapper>
      </S.ResourceCreatorSection>
    </>
  )
}

export { HomePageHello }
