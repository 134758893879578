import React from "react"
import { Link } from "gatsby-plugin-react-intl"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

import * as S from "./styles"

interface Organization {
  id: number
  name: string
}

interface ResourceTileProps {
  name: string
  link: string
  kind?: string
  organizations?: Organization[]
  progressPercents?: number
}

const ResourceTile: React.FC<ResourceTileProps> = ({
  name,
  link,
  kind,
  organizations,
  progressPercents,
}) => {
  const showProgress = progressPercents !== undefined

  return (
    <S.StyledCard>
      <S.StyledCardActionArea component={Link} to={link}>
        <S.StyledCardContent>
          <S.Name>{name}</S.Name>
          {organizations?.map(({ id, name }) => (
            <S.OrganizationName key={id}>{name}</S.OrganizationName>
          ))}
        </S.StyledCardContent>
        <S.StyledCardBottomContent>
          {kind && <S.Kind>{kind}</S.Kind>}
          <S.Forward progressPercents={progressPercents}>
            {showProgress && `${progressPercents}%`}
            <ArrowForwardIcon fontSize="small" />
          </S.Forward>
        </S.StyledCardBottomContent>
        {showProgress && <S.ProgressBar progressPercents={progressPercents} />}
      </S.StyledCardActionArea>
    </S.StyledCard>
  )
}

export { ResourceTile }
