import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { CardContent, Button } from "@mui/material"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
// import { styled } from "@mui/material/styles"
// import styled from "@emotion/styled"

import lessonplanImage from "../../../../images/lessonplan.svg"

export const Main = styled.main`
  padding: 24px;
  width: 100%;
`

export const Wrapper = styled.div`
  margin: 160px 0 60px 0;

  @media screen and (min-width: 800px) {
    margin: 200px 10% 70px 10%;
  }
  @media screen and (min-width: 1200px) {
    margin: 200px 20% 70px 20%;
  }
`

export const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40vh 0;
`

export const Lessonplan = styled.div`
  width: 100%;
  height: 180px;
  background-image: url(${lessonplanImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const Header = styled.h2`
  text-align: center;
  font-size: 1.2rem;
  margin: 0 0 10px 0;

  @media screen and (min-width: 800px) {
    font-size: 1.5rem;
    margin: 0 0 30px 0;
  }
`

export const SubHeader = styled.h3`
  text-align: center;
  font-size: 1rem;
  margin: 30px 0 0 0;

  @media screen and (min-width: 800px) {
    font-size: 1.2rem;
    margin: 40px 0 0 0;
  }
`

export const MainDescription = styled.p`
  text-align: center;
  margin: 10px 0 30px 0;
  font-size: 0.9rem;

  @media screen and (min-width: 800px) {
    margin: 30px 0 50px 0;
    font-size: 1.1rem;
  }
`

export const Description = styled.p`
  text-align: center;
  margin: 30px 0 0 0;
  font-size: 0.9rem;

  @media screen and (min-width: 800px) {
    margin: 40px 0 0 0;
    font-size: 1.1rem;
  }
`

export const ImageColumns = styled.div`
  margin: 0 auto;
  max-width: 300px;

  display: grid;
  grid-template-areas: "title1" "image1" "title2" "image2" "title3" "image3";
  grid-template-columns: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 800px) {
    grid-template-areas: "title1 title2 title3" "image1 image2 image3";
    grid-template-columns: 30% 30% 30%;
    column-gap: 5%;
    max-width: 900px;
  }
`
export const Columns = styled.div`
  @media screen and (min-width: 800px) {
    display: flex;
    column-gap: 12vw;
    justify-content: center;
    margin: 0 auto;
  }
`

export const Column = styled.div<{ gridArea?: string }>`
  flex: 1 1 0px;
  ${props => (props.gridArea ? `grid-area: ${props.gridArea}` : "")}
`

export const ColumnsDescription = styled.p`
  text-align: center;
  margin: 10px 0 0 0;
  font-size: 0.9rem;

  @media screen and (min-width: 800px) {
    margin: 20px 0 0 0;
    font-size: 1.1rem;
  }
`

export const ElevatedImg = styled(Img)`
  border: solid 1px #0003;
  border-radius: 5px;
`

export const Highlighted = styled.span`
  color: ${props => props.theme.palette.primary.light};
  font-weight: bold;
  font-size: 1.4rem;

  @media screen and (min-width: 800px) {
    font-size: 1.8rem;
  }
`

export const DescriptionButtonContainer = styled.div`
  margin: 30px auto 0 auto;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  @media screen and (min-width: 800px) {
    margin: 50px auto 0 auto;
  }
`

export const CTAButton = styled(props => (
  <Button
    variant="contained"
    color="primary"
    endIcon={<ArrowForwardIcon />}
    {...props}
  />
))`
  display: flex;
  padding: 6px 30px;
`
export const BrowseButton = styled(props => (
  <Button
    variant="contained"
    color="primary"
    endIcon={<ArrowForwardIcon />}
    {...props}
  />
))`
  display: flex;
  padding: 6px 30px;
  background: #fff;
  color: #000;
  &:hover {
    background: #fff;
    color: #000;
  }
`
export const ImproveButton = styled(props => (
  <Button
    variant="outlined"
    // color=""
    endIcon={<ArrowForwardIcon />}
    {...props}
  />
))`
  display: flex;
  padding: 6px 30px;
  background: #fff;
  color: #000;
  border-color: #ccc;
  &:hover {
    background-color: rgb(110 110 110 / 4%);
    border-color: #000;
  }
`

export const TableCardContent = styled(props => <CardContent {...props} />)`
  padding: 0;
  :last-child {
    padding-bottom: 0;
  }
`

export const MaterialUrl = styled.a`
  text-decoration: none;
  &:link {
    color: ${props => props.theme.palette.primary.dark};
  }
`

export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const ResourceCatalogSection = styled.div`
  padding: 24px;
  width: 100%;
  background-color: ${props => props.theme.palette.primary.light};
  color: #fff;
`

export const ResourceCatalogWrapper = styled.div`
  margin: 120px 0 120px 0;

  @media screen and (min-width: 800px) {
    margin: 120px 10% 120px 10%;
  }
  @media screen and (min-width: 1200px) {
    margin: 120px 20% 120px 20%;
  }
`

export const ResourceCreatorSection = styled.div`
  padding: 24px;
  width: 100%;
  background-color: #fff;
  color: ${props => props.theme.palette.text.primary};
`

export const ResourceCreatorWrapper = styled.div`
  margin: 60px 0 60px 0;

  @media screen and (min-width: 800px) {
    margin: 60px 10% 60px 10%;
  }
  @media screen and (min-width: 1200px) {
    margin: 60px 20% 60px 20%;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 100px;
`

export const SmallDivider = styled.div`
  width: 100%;
  height: 40px;
`
