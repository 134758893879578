import { EducationMaterialProgress } from "../src/components/sets/user/PlanResourcePage/PlanResourcePage"

export const getProgressPercentsFromCourseRegistration = (
  educationMaterialProgressList: EducationMaterialProgress[]
) => {
  return Number(
    (
      (educationMaterialProgressList?.reduce(
        (prev, curr) => prev + (curr.courseProgress?.passed ? 1 : 0),
        0
      ) /
        educationMaterialProgressList?.length) *
      100
    ).toFixed()
  )
}
