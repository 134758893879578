import { gql } from "@apollo/client"

export const userResourceListQuery = gql`
  query UserCourseList {
    userCourseRegistrationList {
      id
      code
      cost
      costCurrency
      course {
        id
        name
        description
        slug
        websiteUrl
        courseorganizationSet {
          organization {
            id
            name
          }
        }
      }
      educationMaterialProgressList {
        educationMaterial {
          id
          slug
          name
          websiteUrl
          kind {
            id
            name
          }
        }
        courseProgress {
          id
          passed
          examined
        }
      }
    }
  }
`

export const userResourceQuery = gql`
  query UserCourse($registrationId: Int!) {
    userCourseRegistration(registrationId: $registrationId) {
      id
      code
      cost
      costCurrency
      course {
        id
        name
        description
        slug
        websiteUrl
        courseorganizationSet {
          organization {
            id
            name
          }
        }
      }
      educationMaterialProgressList {
        educationMaterial {
          id
          slug
          name
          websiteUrl
          kind {
            id
            name
          }
        }
        courseProgress {
          id
          passed
          examined
        }
      }
    }
  }
`
