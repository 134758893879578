import { useQuery } from "@apollo/client"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-react-intl"
import React, { useContext, useState } from "react"
import { HomePageContent } from "./HomePageContent"
import { CircularProgress } from "@mui/material"

import * as S from "./style"
import { AuthContext } from "../../../../containers/AuthContext"
import { useIntlPathName } from "../../../../hooks/useIntlPathName"
import { HomeLayout, Tab } from "../HomeLayout"
import { HomePageHello } from "./HomePageHello"
import { userResourceListQuery } from "../../user/queries"
import { userQuery } from "../../auth/LoginPage/queries"

interface Organization {
  id: number
  name: string
}

interface OrganizationSet {
  organization: Organization
}

interface EducationMaterialKind {
  id: number
  name: string
}

interface EducationMaterial {
  id: number
  name: string
  slug: string
  websiteUrl: string
  kind: EducationMaterialKind
}

interface Course {
  id: number
  name: string
  slug: string
  description: string
  websiteUrl: string
  educationMaterials: EducationMaterial[]
  courseorganizationSet: OrganizationSet[]
}

interface CourseProgress {
  id: number
  passed: boolean
  examined: boolean
}

interface EducationMaterialProgress {
  courseProgress?: CourseProgress | null
  educationMaterial: EducationMaterial
}

export interface UserCourseRegistration {
  id: number
  code: string
  cost: number
  costCurrency: string
  course: Course
  educationMaterialProgressList: EducationMaterialProgress[]
}

interface Data {
  userCourseRegistrationList: UserCourseRegistration[]
}

const HomePage: React.FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const pathname = useIntlPathName()

  const { loading: authLoading, authenticated } = useContext(AuthContext)
  const user = useQuery(userQuery, {
    fetchPolicy: "cache-first",
    skip: !authenticated,
  })
  const userCourseList = useQuery<Data>(userResourceListQuery, {
    fetchPolicy: "cache-and-network",
    skip: !authenticated,
  })

  const selectedCourseId = queryString.parse(location.search).course
  const selectedCourse =
    selectedCourseId &&
    userCourseList.data?.userCourseRegistrationList?.length > 0
      ? userCourseList.data.userCourseRegistrationList.find(
          registration => String(registration.course.id) === selectedCourseId
        )
      : userCourseList.data?.userCourseRegistrationList?.length > 0 &&
        userCourseList.data?.userCourseRegistrationList[0]

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleCourseClick = (course: any) => {
    const url = queryString.stringifyUrl({
      url: pathname,
      query: { course: course?.id ? course?.id : undefined },
    })

    navigate(url)
  }

  const handleTabChange = (tab: Tab) => {
    const query = queryString.parse(location.search)
    navigate(
      queryString.stringifyUrl({
        url: tab.path,
        query,
      })
    )
  }

  const userCourseRegistrationList =
    userCourseList?.data?.userCourseRegistrationList
  const hasCourses = userCourseRegistrationList?.length > 0

  return (
    <>
      <HomeLayout
        variant={hasCourses ? "default" : "dark"}
        activeTab="app"
        onTabChange={handleTabChange}
      >
        {authLoading || userCourseList.loading ? (
          <S.Loading>
            <CircularProgress color="primary" />
          </S.Loading>
        ) : authenticated && hasCourses ? (
          <HomePageContent
            userName={user?.data?.user.username}
            userCourseRegistrationList={userCourseRegistrationList}
          />
        ) : (
          <HomePageHello userName={user?.data?.user.username} />
        )}
      </HomeLayout>
    </>
  )
}
export { HomePage }
