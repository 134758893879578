import { Card, CardActionArea, CardContent } from "@mui/material"
import { Link } from "gatsby-plugin-react-intl"
import React from "react"
import styled, { css } from "styled-components"

export const Name = styled.h4`
  grid-area: name;
  margin: 0 0 10px 0;
  font-weight: normal;
  font-size: 0.9rem;
`

export const WebsiteUrl = styled.a`
  margin: 0 10px 20px 0;
  text-decoration: none;
  &:link {
    color: ${props => props.theme.palette.primary.dark};
  }
`

export const OrganizationName = styled.span`
  grid-area: organizationName;
  display: block;
  text-decoration: none;
  font-size: 0.8rem;
`

export const Kind = styled.span`
  grid-area: kind;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.4);
  margin-top: auto;
`

export const Forward = styled.div<{ progressPercents: number }>`
  grid-area: forward;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  ${props =>
    `
      color: ${
        props.progressPercents && props.progressPercents >= 100
          ? "#429b00"
          : "unset"
      };
      svg {
        fill: ${
          props.progressPercents && props.progressPercents >= 100
            ? "#429b00"
            : "unset"
        };
      }
    `}
`

export const MoreLink = styled(props => <Link {...props} />)`
  width: 100%;
  background: #fafafa;
  padding: 15px;
  margin: 0;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  text-decoration: none;
  color: #000;
`

export const StyledCard = styled(props => <Card {...props} />)`
  cursor: pointer;
`

export const StyledCardActionArea = styled(props => (
  <CardActionArea {...props} />
))``

export const StyledCardContent = styled(props => <CardContent {...props} />)`
  width: 100%;
  height: 100%;
`

export const StyledCardBottomContent = styled(props => (
  <CardContent {...props} />
))`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 0;
`

export const ProgressBar = styled.div<{ progressPercents: number }>`
  height: 6px;
  width: 100%;
  background: ${props =>
    `linear-gradient(to right, #429b00 ${props.progressPercents}%, #00000010 ${props.progressPercents}%)`};
`
